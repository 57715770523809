<template>
	<div>
		<v-btn v-if="!oauth_link || !oauth_link.includes(3)" icon x-large color="#4285F4" @click="login('Google')"><v-icon>icon-google</v-icon></v-btn>
		<v-btn v-if="!oauth_link || !oauth_link.includes(1)" icon x-large color="#4680C2" @click="login('vkontakte')"><v-icon>icon-vkontakte</v-icon></v-btn>
		<v-btn v-if="!oauth_link || !oauth_link.includes(7)" icon x-large color="#000000" @click="login('apple')"><v-icon>icon-apple</v-icon></v-btn>
	</div>
</template>

<script>
export default {
	props: ['oauth_link'],
	data() {
		return {
		};
	},
	methods: {
		login(provider) {
			window.ajax("/api/oauth/" + provider + "/url", {}, function(
				data,
				error
			) {
				if (!error) {
					window.location = data.url;
				}
			});
		},
	}
};
</script>