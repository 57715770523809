<template>
	<v-list>
		<v-list-item :to="{ name: 'new' }">
			<v-badge color="green" :value="ncu ? true : false">
				<template v-slot:badge>{{ ncu }}</template>
				<v-list-item-title>Обновления</v-list-item-title>
			</v-badge>
		</v-list-item>
		<v-list-item :to="{ name: 'look', params: { section: 'series' }}">
			<v-list-item-title>Мои сериалы</v-list-item-title>
		</v-list-item>
		<v-list-item :to="{ name: 'look', params: { section: 'anime' }}">
			<v-list-item-title>Мои аниме</v-list-item-title>
		</v-list-item>
		<v-list-item :to="{ name: 'look', params: { section: 'movie' }}">
			<v-list-item-title>Посмотреть позже</v-list-item-title>
		</v-list-item>
		<v-list-item :to="{ name: 'watched' }">
			<v-list-item-title>Просмотренное видео</v-list-item-title>
		</v-list-item>
		<v-list-item :to="{ name: 'watched-movies' }">
			<v-list-item-title>Просмотренные фильмы</v-list-item-title>
		</v-list-item>
		<v-list-item :to="{ name: 'fave' }" v-if="$vuetify.breakpoint.width <= 320">
			<v-list-item-title>Избранное</v-list-item-title>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
	props: ["ncu"]
};
</script>
