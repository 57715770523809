<template>
<div>
	<v-overlay :value="loading" v-if="type" opacity=0>
		<v-sheet style="border-radius: 50%; opacity: 0.5; width: 80px; height: 80px; padding-left: 10px; padding-top: 10px;">
		<v-progress-circular size="60" indeterminate></v-progress-circular>
		</v-sheet>
	</v-overlay>
	<v-progress-circular indeterminate v-if="!type && loading"></v-progress-circular>
	<v-card class="error" v-if="error" dark>
		<v-card-title>Ошибка</v-card-title>
		<v-card-text>
		<pre>{{ error }}</pre>
		</v-card-text>
		<v-card-actions>
			<v-btn @click.prevent="fetchData">Обновить страницу</v-btn>
		</v-card-actions>
	</v-card>
</div>
</template>
<script>
export default {
	props: ["error", "loading", "type"],
	data() {
		return {};
	}
};
</script>
