<template>
	<div v-if="enabled">
		<v-btn icon @click="active = true; visible = !visible" id="chat-button">
			<v-badge color="green" overlap :value="unread ? true : false">
				<template v-slot:badge>{{ unread }}</template>
				<v-icon>icon-chat</v-icon>
			</v-badge>
		</v-btn>
		<component v-if="active" :class="visible ? 'visible' : ''" :is="chatBox" @onClose="close" />
		<v-snackbar v-model="newMessage" :timeout="10000" bottom>
			Новое сообщение: {{ message }}
		</v-snackbar>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active: false,
			visible: false,
			enabled: false,
			message: null,
			newMessage: false,
			unread: 0
		};
	},
	created() {
		this.init();
	},
	computed: {
		chatBox() {
			return () => import("../components/helper/chat-box.vue");
		}
	},
	watch: {
		visible(val) {
			this.message = null;
			if (val && this.unread) {
				this.unread = 0;
				this.$socket.send({ action: "read" });
			}
		}
	},
	methods: {
		init() {
			let t = this;
			if (t.$socket && t.$store.state.user.user) {
				t.enabled = true;

				t.$socket.bind("ready", function() {
					t.$socket.send({
						action: "chat_login",
						login: t.$store.state.user.user.login
					});
				});

				t.$socket.bind("unread", function(num) {
					t.unread = t.visible ? 0 : num;
					if (t.visible && num) {
						t.$socket.send({ action: "read" });
					}
				});
				t.$socket.bind("message", function(message) {
					if (!t.visible && message.sender != 'self') {
						t.unread++;
						t.message = message.message;
						t.newMessage = true;
					}
				});
			}
		},
		close() {
			this.visible = false;
		}
	}
};
</script>

<style scoped>
#chat-button {
	position: fixed;
	bottom: 15px;
	right: 10px;
	z-index: 3;
	background-color: transparent;
}
#chat-button i:before {
	font-size: 40px;
}
</style>