export default {
	namespaced: true,
	state: {
		categories: {},
		showSidebar: true
	},
	mutations: {
		setCategories(state, categories) {state.categories = categories;},
		setShowSidebar(state, status) {state.showSidebar = status;},
		toggleSidebar(state) {state.showSidebar = !state.showSidebar;},
	},
	actions: {
		getCategories(store) {
			let v = 2, stored = localStorage.getItem('categories');
			if (stored) {
				stored = JSON.parse(stored);
				if (typeof stored.v == 'undefined' || stored.v < v) stored = undefined;
				else stored = stored.data;
			}
			if (stored) store.commit('setCategories', stored);
			else {
				window.ajax('/api/getCategories', {}, function(data, error) {
					if (!error) {
						store.commit('setCategories', data);
						localStorage.setItem('categories', JSON.stringify({data: data, v: v}));
					}
				});
			}
		}
	}
}
