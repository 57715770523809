// Best place to find information on XHR features is:
// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest

var reqfields = [
	'responseType', 'withCredentials', 'timeout', 'onprogress',
];

// Simple and small ajax function
// Takes a parameters object and a callback function
// Parameters:
//  - url: string, required
//  - headers: object of `{header_name: header_value, ...}`
//  - body:
//      + string (sets content type to 'application/x-www-form-urlencoded' if not set in headers)
//      + FormData (doesn't set content type so that browser will set as appropriate)
//  - method: 'GET', 'POST', etc. Defaults to 'GET' or 'POST' based on body
//  - cors: If your using cross-origin, you will need this true for IE8-9
//
// The following parameters are passed onto the xhr object.
// IMPORTANT NOTE: The caller is responsible for compatibility checking.
//  - responseType: string, various compatability, see xhr docs for enum options
//  - withCredentials: boolean, IE10+, CORS only
//  - timeout: long, ms timeout, IE8+
//  - onprogress: callback, IE10+
//
// Callback function prototype:
//  - response
//    + if responseType set and supported by browser, this is an object of some type (see docs)
//    + otherwise if request completed, this is the string text of the response
//    + if request is aborted, this is "Abort"
//    + if request times out, this is "Timeout"
//    + if request errors before completing (probably a CORS issue), this is "Error"
//  - statusCode from request
//    + Possibly null or 0 (i.e. falsy) if an error occurs
//  - request object
//
// Returns the request object. So you can call .abort() or other methods
//
// DEPRECATIONS:
//  - Passing a string instead of the params object has been removed!
//
function ajax(url, params, callback) {
	var headers = params.headers || {}
		, body = params.body
		, method = params.method || (body ? 'POST' : 'GET')
		, called = false;

	var req = new XMLHttpRequest;

	function cb(statusCode, responseText) {
		return function () {
			if (!called) {
				var code = req.status === undefined ? statusCode : req.status;
				var res = req.status === 0 ? 'Error' : (req.response || req.responseText || responseText);
				var data;
				called = true;

				if (code == 401 && window.store) {
					window.store.commit('user/logout');
				}

				// Try to parse json res
				try {
					data = JSON.parse(res);
				} catch (e) {
					data = res;
				}

				if (!code || code >= 400) {
					return callback(data, code, req);
				}

				callback(data, null, req);
			}
		};
	}

	req.open(method, url, true);

	var success = req.onload = cb(200);
	req.onreadystatechange = function () {
		if (req.readyState === 4) success();
	};
	req.onerror = cb(null, 'Error');
	req.ontimeout = cb(null, 'Timeout');
	req.onabort = cb(null, 'Abort');

	//setDefault(headers, 'X-CSRF-TOKEN', document.querySelector('meta[name=token]').getAttribute('content'));

	if (body) {
		setDefault(headers, 'X-Requested-With', 'XMLHttpRequest')

		setDefault(headers, 'Content-Type', 'application/json');
	}

	for (var i = 0, len = reqfields.length, field; i < len; i++) {
		field = reqfields[i];
		if (params[field] !== undefined)
			req[field] = params[field];
	}

	for (let field in headers)
		req.setRequestHeader(field, headers[field]);

	var q = typeof body == 'object' ? JSON.stringify(body) : body;

	req.send(q);

	return req;
}

function setDefault(obj, key, value) {
	obj[key] = obj[key] || value;
}

function fetchData(url, params) {
	return new Promise((resolve) => {
		ajax(url, params, function(data, error) {
			if (!error) {
				resolve(data);
			} else {
				resolve({'error': process.env.NODE_ENV != 'production' ? data : 'Попробуйте позже'});
			}
		});
	});
}

export {fetchData, ajax};
//export default ajax;