import cookie from '../plugins/cookie.js';

/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
export default {
	namespaced: true,
	state: {
		token: null,
		user: null
	},
	mutations: {
		setToken(state, token) {state.token = token;},
		setUser(state, user) {state.user = user;},
		authSuccess(state, payload) {
			cookie.set('token', payload.token, 86400 * 30 * 6);
			localStorage.setItem('user', JSON.stringify(payload.user));
			state.token = payload.token;
			state.user = payload.user;
			state.user.mode = payload.mode;
		},
		logout(state) {
			cookie.set('token', '', -1);
			localStorage.removeItem('user');
			state.token = '';
			state.user = '';
			window.router.push({name: 'login'});
		},
		setTheme(state, isDark) {
			state.user.mode = isDark ? 'dark' : 'light';
			localStorage.setItem('user', JSON.stringify(state.user));
		}
	},
	actions: {
		initToken(store) {
			try {
				let user_c = localStorage.getItem('user'), user = false, token = cookie.get('token');
				if (user_c) {
					try {
						user = JSON.parse(user_c);
					} catch (e) {}
				}
				if (!user && token) {
					window.ajax("/api/user/get-base", {}, function(data, error) {
						if (data && !error) {
							user = data;
							localStorage.setItem('user', JSON.stringify(user));
						}
					});
				}

				if (user) store.commit('setUser', user);
				if (token) store.commit('setToken', token);
			} catch (err) {
				alert(err);
			}
		},
		logout(store) {
			window.ajax('/api/logout', {}, function() {
				store.commit('logout');
			});
		}
	},
	getters: {
		isAuth: state => {
			return !!state.token;
		}
	}
}
