<template>
	<v-snackbar :timeout="snackTimeout" v-model="snackWithButtons" bottom left>
		{{ snackWithBtnText }}
		<v-spacer />
		<v-btn dark text @click.native="refreshApp" v-if="snackBtnText ? true : false">{{ snackBtnText }}</v-btn>
		<v-btn icon @click="snackWithButtons = false">
			<v-icon>icon-close</v-icon>
		</v-btn>
	</v-snackbar>
</template>

<script>
export default {
	data() {
		return {
			refreshing: false,
			registration: null,
			snackBtnText: "",
			snackWithBtnText: "",
			snackWithButtons: false,
			snackTimeout: 5000
		};
	},
	created() {
		let t = this;
		// Listen for swUpdated event and display refresh snackbar as required.
		document.addEventListener("swUpdated", t.showRefreshUI, {
			once: true
		});
		document.addEventListener("skipWaiting", () => {
			t.refreshApp();
		});
		// Refresh all open app tabs when a new service worker is installed.
		navigator.serviceWorker.addEventListener("controllerchange", () => {
			if (t.refreshing) return;
			t.refreshing = true;
			window.location.reload();
		});
	},
	methods: {
		showRefreshUI(/*e*/) {
			// Display a snackbar inviting the user to refresh/reload the app due
			// to an app update being available.
			// The new service worker is installed, but not yet active.
			// Store the ServiceWorkerRegistration instance for later use.
			/*this.registration = e.detail;
			this.snackBtnText = "Обновить";
			this.snackWithBtnText = "Доступна новая версия сайта.";
			this.snackWithButtons = true;*/
		},
		refreshApp() {
			this.snackWithButtons = false;
			// Protect against missing registration.waiting.
			if (!this.registration || !this.registration.waiting) {
				return;
			}
			this.registration.waiting.postMessage("skipWaiting");
		}
	},
	watch: {
		wsFail(val) {
			if (val) {
				this.snackBtnText = "";
				this.snackWithBtnText = "Не удалось соединиться с websockets сервером. Регистрация просмотров и чат не будут работать. Скорей всего соединение блокирует какое-нибудь расширение.";
				this.snackWithButtons = true;
				this.snackTimeout = 60000;
			}
		}
	},
	computed: {
		wsFail() {
			return this.$store.state.ws.fail;
		}
	}
};
</script>