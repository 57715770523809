export default {
	namespaced: true,
	state: {
		count: {ncu: 0},
		fail: false
	},
	mutations: {
		setCount(state, e) {
			state.count.ncu = e.ncu;
		},
		setFail(state, val) {
			state.fail = val;
		}
	},
	actions: {
		listenCunters(store) {
			this.$socket.send({action: 'get_counts'});
			this.$socket.bind('counts', function(e) {
				store.commit('setCount', e);
			});
		}
	}
}
