export default function throttle(fn, threshhold, scope) {
	threshhold || (threshhold = 250);
	var previous, deferTimer;

	return function () {
		var context = scope || this,
			current = Date.now(),
			args = arguments;
		clearTimeout(deferTimer);
		if (previous && current < previous + threshhold) {
			deferTimer = setTimeout(function () {previous = current; fn.apply(context, args); }, threshhold);
		} else {
			previous = current;
			fn.apply(context, args);
		}
	};
}