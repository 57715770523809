import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/es5/locale/ru';
import '../assets/app.scss';
import store from '../store'

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: { ru },
		current: 'ru',
	},
	icons: {
//		iconfont: 'md',
		values: {
			close: 'icon-close',
			cancel: 'icon-close',
			clear: 'icon-close',
			delete: 'icon-close',
			next: 'icon-chevron_right',
			prev: 'icon-chevron_left',
			info: 'icon-attention-circled',
			ratingEmpty: 'icon-heart-empty',
			ratingFull: 'icon-heart',
			expand: 'icon-down'
		}
	},
	theme: {
		dark: store.state.user.user ? store.state.user.user.mode == 'dark' : false,
		themes: {
			light: {
				primary: '#4caf50',
				secondary: '#4caf50',
				tertiary: '#495057',
				accent: '#82B1FF',
				error: '#f55a4e',
				info: '#00b3aa',
				success: '#5cb860',
				warning: '#ffa21a'
			},
			dark: {
				primary: '#4caf50',
			}
		},
		options: { variations: false }
	}
});
