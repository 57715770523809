<template>
	<v-list>
		<v-list-item :to="{ name: 'profile'}">
			<v-list-item-action>
				<v-icon>icon-user</v-icon>
			</v-list-item-action>
			<v-list-item-title>Профиль</v-list-item-title>
		</v-list-item>

		<v-list-item :to="{ name: 'tariffs'}">
			<v-list-item-action>
				<v-icon>icon-credit-card</v-icon>
			</v-list-item-action>
			<v-list-item-title>Оплата доступа</v-list-item-title>
		</v-list-item>

		<v-list-item :to="{ name: 'referral'}">
			<v-list-item-action>
				<v-icon>icon-plus</v-icon>
			</v-list-item-action>
			<v-list-item-title>Реферальная система</v-list-item-title>
		</v-list-item>

		<v-list-item :to="{ name: 'pin'}">
			<v-list-item-action>
				<v-icon>icon-link</v-icon>
			</v-list-item-action>
			<v-list-item-title>Привязка устройств</v-list-item-title>
		</v-list-item>

		<v-list-item :to="{ name: 'mobile'}">
			<v-list-item-action>
				<v-icon>icon-download</v-icon>
			</v-list-item-action>
			<v-list-item-title>Android приложение</v-list-item-title>
		</v-list-item>

		<v-divider />

		<v-list-item @click="logout">
			<v-list-item-action>
				<v-icon>icon-logout</v-icon>
			</v-list-item-action>
			<v-list-item-title>Выход</v-list-item-title>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
	methods: {
		logout() {
			this.$store.dispatch("user/logout");
		}
	}
};
</script>
