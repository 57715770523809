<template>
	<v-container fluid fill-height>
		<v-layout align-center justify-center>
			<v-flex xs12 sm8 md6>
				<material-card color="info" title="Восстановление пароля">
					<v-card>
						<v-alert :value="error" type="error">{{ error }}</v-alert>
						<v-alert :value="success" type="success">{{ success }}</v-alert>

						<v-form ref="form" v-model="valid" @submit="validate">
							<v-card-text v-if="!hideEmail">
								<v-text-field
									prepend-icon="icon-mail"
									v-model="email"
									:rules="emailRules"
									name="email"
									label="Email"
									validate-on-blur
									required
								></v-text-field>
							</v-card-text>

							<v-card-actions>
								<v-btn :to="{name: 'login'}">Назад</v-btn>
								<v-spacer></v-spacer>
								<div v-if="!loading && !hideEmail">
									<v-btn
										color="success"
										type="submit"
									>Восстановить пароль</v-btn>
								</div>
								<div v-else>
									<v-progress-linear :indeterminate="true"></v-progress-linear>
								</div>
							</v-card-actions>
						</v-form>
					</v-card>
				</material-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { Validator } from "../../mixins/Validator";

export default {
	mixins: [Validator],
	data: () => ({
		success: null,
		error: null,
		loading: false,
		valid: false,
		email: null,
		hideEmail: false
	}),
	created() {
		let t = this;
		document.title = "Восстановление пароля";

		if (t.$route.query && t.$route.query.token) {
			t.loading = true;
			t.hideEmail = true;

			window.ajax("/api/reset/" + t.$route.query.token, {}, function(
				data,
				error
			) {
				t.loading = false;
				if (error && !data.error) data.error = error;
				if (!data.error) {
					t.$store.commit("user/authSuccess", {
						token: data.token,
						user: data.user
					});

					t.$router.replace({
						name: "profile",
						query: { reset: t.$route.query.type }
					});
				} else {
					t.error = data.error;
					t.valid = false;
				}
			});
		}
	},
	methods: {
		validate(e) {
			e.preventDefault();
			var t = this;
			t.error = null;
			if (!t.$refs.form.validate()) return;
			t.loading = true;

			window.ajax(
				"/api/reset/send",
				{ body: { email: t.email } },
				function(data, error) {
					t.loading = false;
					if (error && !data.error) data.error = error;
					if (!data.error) {
						t.success = data.success;
						t.valid = false;
						t.hideEmail = true;
					} else {
						t.error = data.error;
						t.valid = false;
					}
				}
			);
		}
	}
};
</script>
