var MultiSocket = function (url, send_on_open) {
	var ms = this;

	ms.url = url;

	ms.listeners = {};
	ms.senders = [send_on_open];
	ms.opened = false;
	ms.debug = false;
	ms.send_on_open = send_on_open;

	ms.connect = function () {
		try {
			ms.sock = new WebSocket(ms.url);
		} catch (err) {
			//console.error(err);
			return false;
		}

		ms.sock.onopen = function () {
			ms.opened = true;
			if (ms.senders.length) for (var i = 0; i < ms.senders.length; i++) ms.send(ms.senders[i]);
			ms.ping();
		};

		ms.ping = function () {
			if (!ms.sock || ms.sock.readyState !== 1) return;
			ms.sock.send(JSON.stringify({ 'action': 'ping' }));
			setTimeout(ms.ping, 300000);
		};

		ms.sock.onmessage = function (e) {
			try {
				e = JSON.parse(e.data);
				var act = e[0]; e = e[1];
			} catch (error) { return; }

			if (typeof ms.listeners[act] == 'function') ms.listeners[act](e);
		};

		ms.send = function (params) {
			if (ms.opened == true) ms.sock.send(JSON.stringify(params));
			else ms.senders.push(params);
		};

		ms.sock.onclose = function (e) {
			ms.opened = false;
			if (e.code != 3000 && e.code != 1000) setTimeout(ms.connect, Math.floor(Math.random() * 50000) + 10000);
		};

		ms.bind = function (action, params, handler, callback) {
			if (typeof params == 'function') {
				ms.listeners[action] = params;
				return true;
			}

			ms.listeners[action] = handler;
			ms.senders.push(params);

			if (typeof callback == 'function') callback();

			ms.send(params);
		};

		ms.unbind = function (action) {
			clearTimeout(ms.listeners[action]);
			delete ms.listeners[action];
		}
	}; // connect

	ms.connect();
};

export default {
	install(Vue, connection, opts = {}) {
		if (!connection) { throw new Error('[vue-native-socket] cannot locate connection') }

		let sock = new MultiSocket(connection, opts);

		Vue.prototype.$socket = sock
	}
}
