<template>
	<v-bottom-navigation v-if="$vuetify.breakpoint.width < 800 && $store.getters['user/isAuth']">
		<v-btn :to="{ name: 'channelList', params: { section: 'movie' }}">
			<span>Фильмы</span>
			<v-icon color="tertiary">icon-movie</v-icon>
		</v-btn>

		<v-btn :to="{ name: 'channelList', params: { section: 'series' }}">
			<span>Сериалы</span>
			<v-icon color="tertiary">icon-series</v-icon>
		</v-btn>

		<v-btn :to="{ name: 'channelList', params: { section: 'anime' }}">
			<span>Аниме</span>
			<v-icon color="tertiary">icon-anime</v-icon>
		</v-btn>
	</v-bottom-navigation>
</template>
<script>

export default {};
</script>
