import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex);

import {fetchData, ajax} from './vendor/nanoajax.js';
window.ajax = ajax;
window.fetchData = fetchData;

import userModule from './store/user.js'
import miscModule from './store/misc.js'
import wsModule from './store/ws.js'

const store = new Vuex.Store({
	modules: {
		'user': userModule,
		'misc': miscModule,
		'ws': wsModule
	}
})

window.store = store;
store.dispatch('user/initToken');
store.dispatch('misc/getCategories');

export default store;
