import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueYandexMetrika from 'vue-yandex-metrika'

import Socket from './plugins/socket.js'
Vue.use(Socket, 'wss://s.atoto.ru', {action: 'subscribe', hash: store.state.user.token})
store.$socket = Vue.prototype.$socket
store.$socket.sock.onerror = function() {
	store.commit('ws/setFail', true);
}
store.dispatch('ws/listenCunters');

Vue.config.productionTip = false;

import Progress from "./components/helper/progress.vue";
Vue.component('Progress', Progress);

import MaterialCard from "./components/card.vue";
Vue.component('material-card', MaterialCard);

import vuetify from './plugins/vuetify';
import './registerServiceWorker'

Vue.use(VueYandexMetrika, {
	id: 54010954,
	router: router,
	env: 'production',
	skipSamePath: false,
	options: {clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true}
})

Vue.prototype.$eventBus = new Vue();

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
