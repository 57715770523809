export const Validator = {
	computed: {
		emailRules: function () {
			return [
				v => !!v || "без email авторизация невозможна",
				v => {
					const pattern = /^[-0-9a-z_.]{1,30}@([-0-9a-z_.]+\.){1,20}([0-9a-z]){2,5}$/i;
					return (
						pattern.test(v) || "пожалуйста, введите корректный email"
					);
				}
			]
		}
	},
	methods: {
		rule_length_max: len => v =>
			(v || "").length <= len ||
			`Длина должна быть не более ${len} символов`,
		rule_length_min: len => v =>
			(v || "").length > len ||
			`Длина должна быть не меньше ${len} символов`,
		rule_password: function(v) {
			return (v || " ").match(/^([-0-9А-яёA-z_. #!,])+$/)
				? true
				: false || "В пароле допустимы только русские или английские буквы, цифры и символы _-!#,"
		},
		rule_required: v => !!v || "Это поле обязательно для заполнения",
		rule_login: function(v) {
			return (v || " ").match(/^([-0-9А-яёA-z_. ])+$/)
				? true
				: false || "В логине допустимы только русские или английские буквы, цифры, знаки - и _"
		},
	}
}
