<template>
	<v-app>
		<header class="main-header">
			<HeaderBlock />
		</header>
		<v-main>
			<transition name="fade" mode="out-in">
				<router-view />
			</transition>
			<refresh />
		</v-main>
		<v-footer app>
			<BottomBlock />
		</v-footer>
		<chat-button />
	</v-app>
</template>
<script>
import HeaderBlock from "./components/header.vue";
import BottomBlock from "./components/bottom.vue";
import Refresh from "./components/refresh.vue";
import ChatButton from "./components/chat-button.vue";

export default {
	components: { HeaderBlock, BottomBlock, Refresh, ChatButton },
	created() {
	}
};
</script>