<template>
	<v-app-bar
		id="core-toolbar"
		app
		elevate-on-scroll
		v-if="$store.getters['user/isAuth']"
	>
		<v-toolbar-title>
			<v-btn text :to="{ name: 'root' }"><img src='/image/logo.svg' height="32" /></v-btn>
		</v-toolbar-title>
		<v-toolbar-items class="tertiary--text" v-if="$vuetify.breakpoint.width > 800">
			<v-btn class="toolbar-items" text :to="{ name: 'channelList', params: { section: 'movie' }, query: query != '' ? { search: query } : null}">
				<v-icon color="tertiary">icon-movie</v-icon>
				<span>Фильмы</span>
			</v-btn>
			<v-btn class="toolbar-items" text :to="{ name: 'channelList', params: { section: 'series' }, query: query != '' ? { search: query } : null}">
				<v-icon color="tertiary">icon-series</v-icon>
				<span>Сериалы</span>
			</v-btn>
			<v-btn class="toolbar-items" text :to="{ name: 'channelList', params: { section: 'anime' }, query: query != '' ? { search: query } : null}">
				<v-icon color="tertiary">icon-anime</v-icon>
				<span>Аниме</span>
			</v-btn>
		</v-toolbar-items>

		<v-spacer />
		<v-toolbar-items>
			<v-flex align-center layout py-2>
				<v-text-field
					v-show="searchFocus"
					:click:append="() => {}"
					label="Поиск"
					single-line
					hide-details
					autocomplete="off"
					id="search"
					ref="search"
					v-model="text"
					@click="setPlayerFocus"
				/>
				<v-icon
					color="tertiary"
					@click="searchBtnClick"
					v-text="!searchFocus ? 'icon-search' : 'icon-close'"
					title="Поиск"
				></v-icon>
			</v-flex>
			<v-expand-transition>
				<v-flex align-center layout py-2 v-show="!searchFocus || $vuetify.breakpoint.width > 800">
					<router-link class="toolbar-items" :to="{ name: 'fave' }" title="Избранное" v-if="$vuetify.breakpoint.width > 320">
						<v-icon color="tertiary">icon-heart</v-icon>
					</router-link>
					<v-menu bottom offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn dark icon v-on="on" v-bind="attrs" title="Я смотрю">
								<v-badge color="green" overlap :value="ncu ? true : false">
									<template v-slot:badge>{{ ncu }}</template>
									<v-icon color="tertiary">icon-eye</v-icon>
								</v-badge>
							</v-btn>
						</template>

						<menu-look :ncu="ncu" />
					</v-menu>
					<div v-if="displayAndroid" id="android"><router-link :to="{ name: 'mobile' }"><img src="/image/android-swing.png"></router-link></div>
					<v-menu bottom offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn dark icon v-on="on" v-bind="attrs">
								<v-icon color="tertiary">icon-user</v-icon>
							</v-btn>
						</template>

						<menu-user />
					</v-menu>
				</v-flex>
			</v-expand-transition>
		</v-toolbar-items>
	</v-app-bar>
</template>
<script>
import throttle from "../plugins/throttle.js";
import MenuLook from "./helper/menu-look.vue";
import MenuUser from "./helper/menu-user.vue";

export default {
	components: { MenuLook, MenuUser },
	data() {
		return {
			query: "",
			searchThrottle: null,
			searchFocus: false,
			displayAndroid: true
		};
	},
	created() {
		let t = this;
		t.searchThrottle = t.initSearchThrottle();
		t.query = t.$route.query.search ? t.$route.query.search : "";

		t.searchFocus = t.$vuetify.breakpoint.width > 800;
		/*window.addEventListener('resize', () => {
			t.$nextTick(() => {if (!t.query) t.searchFocus = t.$vuetify.breakpoint.width > 800;});
		});*/

		setTimeout(() => t.displayAndroid = false, 10000);

		t.prev_page = t.$route;
		t.$router.afterEach(to => {
			if (to && !to.query.search && to != t.prev_page) {
				t.prev_page = to;
				if (to.name != 'channelList' && t.prev_page == 'channelList') {
					t.text = '';
					t.searchFocus = false;
				}
			}
		});
	},
	methods: {
		initSearch: function(val) {
			let query = val,
				t = this;

			if (query.length > 1) {
				const rquery = Object.assign({}, this.$route.query);
				rquery.search = query;
				if (t.$route.name == "channelList") {
					t.$router.replace({
						name: "channelList",
						query: rquery
					});
				} else
					t.$router.push({
						name: "channelList",
						params: { section: "all" },
						query: { search: query }
					});
			}
			if (query.length < 1 && t.query.length > 0) {
				if (t.prev_page && t.prev_page.name != "channelList") {
					t.$router.push(t.prev_page);
				} else if (t.prev_page.name == "channelList") {
					const rquery = Object.assign({}, this.$route.query);
					rquery.search = '';
					t.$router.replace({
						name: "channelList",
						query: rquery
					});
				}
			}
			t.query = val;
		},
		initSearchThrottle: function() {
			return throttle(this.initSearch, 500);
		},
		onBlur() {
			if (!this.query) this.searchFocus = false;
		},
		searchBtnClick() {
			let t = this;
			t.text='';
			t.searchFocus = !t.searchFocus;
			if (t.searchFocus) {
				t.$nextTick(() => {
					t.$refs.search.focus();
				});
			}
			t.$eventBus.$emit('player-focus', !t.searchFocus);
		},
		setPlayerFocus() {
			this.$eventBus.$emit('player-focus', false);
		}
	},
	computed: {
		text: {
			get: function() {
				return this.$route.query.search;
			},
			set: function(val) {
				this.searchThrottle(val);
			}
		},
		ncu() {
			return this.$store.state.ws.count.ncu;
		}
	}
};
</script>
<style scoped>
.v-toolbar__title {
	padding-right: 20px;
}
.v-icon {
	margin-right: 5px;
	margin-left: 5px;
}
.v-icon.icon-eye {
	margin-right: 8px;
}

#android {
	width: 0px;
	margin-top: 140px;
}
#android img {
	height: 80px;
}
@media only screen and (max-width: 600px) {
	#android {
		display: none;
	}
}
</style>
