var VueCookie = {
	/*install: function (Vue) {
		Vue.prototype.$cookie = this;
		Vue.cookie = this;
	},*/
	set: function (name, value, time) {
		if (time > 0) {
			var tmp = new Date();
			tmp.setTime(tmp.getTime() + time * 1000);
			document.cookie = name + "=" + encodeURI(value) + "; path=/; expires=" + tmp.toUTCString() + ";";
		} else if (time < 0) {
			document.cookie = name + "=" + encodeURI(value) + "; path=/;";
		} else {
			document.cookie = name + "=" + encodeURI(value) + "; path=/; expires=Wed, 1 Jan 2030 00:00:00 GMT;";
		}
	},

	get: function (name) {
		var cookie = " " + document.cookie,
			search = " " + name + "=",
			setStr = null,
			offset = 0,
			end = 0;
		if (cookie.length > 0) {
			offset = cookie.indexOf(search);
			if (offset != -1) {
				offset += search.length;
				end = cookie.indexOf(";", offset)
				if (end == -1) {
					end = cookie.length;
				}
				try {
					setStr = decodeURI(cookie.substring(offset, end));
				} catch (ex) {
					//console.error(ex);
					setStr = '';
				}
			}
		}
		return setStr;
	}
};

export default VueCookie;