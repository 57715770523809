<template>
	<v-card v-bind="$attrs" :style="styles" v-on="$listeners">
		<helper-offset v-if="hasOffset" :inline="inline" :full-width="fullWidth" :offset="offset">
			<v-card v-if="!$slots.offset" :color="color" class="v-card--material__header" dark>
				<slot name="header">
					<v-layout justify-space-between align-center wrap>
						<div class="title-left">
							<h4 class="title mb-2" v-html="title" />
							<p v-if="text" class="category font-weight-thin" v-html="text" />
						</div>
						<div class="title-right">
							<slot name="headerRight">
								<div v-html="titleRight" />
							</slot>
						</div>
					</v-layout>
				</slot>
			</v-card>
			<slot v-else name="offset" />
		</helper-offset>

		<v-card-text>
			<slot />
		</v-card-text>

		<v-divider v-if="$slots.actions" class="mx-3" />

		<v-card-actions v-if="$slots.actions">
			<slot name="actions" />
		</v-card-actions>
	</v-card>
</template>

<script>
import HelperOffset from "./helper/Offset.vue";

export default {
	components: { HelperOffset },
	inheritAttrs: false,

	props: {
		color: {
			type: String,
			default: "info"
		},
		elevation: {
			type: [Number, String],
			default: 10
		},
		inline: {
			type: Boolean,
			default: false
		},
		fullWidth: {
			type: Boolean,
			default: false
		},
		offset: {
			type: [Number, String],
			default: 24
		},
		title: {
			type: String,
			default: undefined
		},
		titleRight: {
			type: [String, Number, Object],
			default: undefined
		},
		text: {
			type: String,
			default: undefined
		}
	},

	computed: {
		hasOffset() {
			return (
				this.$slots.header ||
				this.$slots.headerRight ||
				this.$slots.offset ||
				this.title ||
				this.text
			);
		},
		styles() {
			if (!this.hasOffset) return null;

			return {
				marginBottom: `${this.offset}px`,
				marginTop: `${this.offset}px`
			};
		}
	}
};
</script>

<style lang="scss">
.v-card--material {
	&__header {
		&.v-card {
			border-radius: 4px;
		}
	}
}
@media only screen and (min-width: 1264px) {
	.info > .layout.wrap {
		flex-wrap: unset;
	}
}
</style>
