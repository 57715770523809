import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Login from '@/views/auth/Login.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import store from './store';

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{ path: '/', name: 'root', component: () => import('@/views/Home.vue') },
		{ path: '/index.html', redirect: '/' },
		{ path: '/list/:section/:sort?', name: 'channelList', component: () => import('@/views/ChannelList.vue'), props: true },
		{ path: '/set/list/:section?', name: 'setList', component: () => import('@/views/SetList.vue'), props: true },
		{ path: '/set/:slug?', name: 'setPage', component: () => import('@/views/SetPage.vue'), props: true },
		{ path: '/item/:address', name: 'channelPage', component: () => import('@/views/ChannelPage.vue'), props: true },
		{ path: '/person/:id', name: 'person', component: () => import('@/views/Person.vue'), props: true },
		{ path: '/video/:id', name: 'video', component: () => import('@/views/Video.vue'), props: true },
		{ path: '/media/:id', name: 'media', component: () => import('@/views/Video.vue'), props: true },

		{ path: '/fave/:section?', name: 'fave', component: () => import('@/views/Fave.vue'), props: { type: 'fave' } },
		{ path: '/watch-later/:section?', name: 'watch-later', component: () => import('@/views/Fave.vue'), props: { type: 'watch-later' } },
		{ path: '/look/:section?', name: 'look', component: () => import('@/views/Look.vue'), props: true },
		{ path: '/new', name: 'new', component: () => import('@/views/New.vue'), props: true },
		{ path: '/watched', name: 'watched', component: () => import('@/views/WatchedList.vue'), props: true },
		{ path: '/watched-movies', name: 'watched-movies', component: () => import('@/views/WatchedMovies.vue'), props: true },

		{ path: '/pin', name: 'pin', component: () => import('@/views/user/Pin.vue'), props: true },

		{ path: '/profile', name: 'profile', component: () => import('@/views/user/ProfileEdit.vue'), props: true },
		{ path: '/billing/history', name: 'billing-history', component: () => import('@/views/user/BillingHistory.vue'), props: true },
		{ path: '/tariffs', name: 'tariffs', component: () => import('@/views/user/Tariffs.vue'), props: true },
		{ path: '/referral', name: 'referral', component: () => import('@/views/user/Referral.vue'), props: true },
		{
			path: '/login/:promo?', name: 'login', component: Login, props: true,
			meta: {
				public: true,
				onlyWhenLoggedOut: true
			}
		},
		{
			path: '/oauth/:provider', name: 'oauth', component: () => import('@/views/auth/Oauth.vue'), props: true,
			meta: {
				public: true,
			}
		},
		{
			path: '/reset', name: 'resetPassword', component: ResetPassword, props: true,
			meta: {
				public: true
			}
		},
		{ path: '/mobile', name: 'mobile', component: () => import('@/views/Mobile.vue'), props: true },
		{
			path: '/help/del', name: 'help-del', component: () => import('@/views/help/Del.vue'), props: true,
			meta: {public: true}
		},
		{
			path: '/help/policy', name: 'help-policy', component: () => import('@/views/help/Policy.vue'), props: true,
			meta: {public: true}
		},
		{ path: '*', component: () => import('@/views/NotFound.vue') }
	],
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}

		if (savedPosition) {
			return savedPosition
		} else {
			return {x: 0, y: 0}
		}
	}
})

router.beforeEach((to, from, next) => {
	const isPublic = to.matched.some(record => record.meta.public)
	const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
	const loggedIn = store.getters['user/isAuth'];

	if (!isPublic && !loggedIn) {
		return next({
			name: 'login',
			query: { redirect: to.fullPath }  // Store the full path to redirect the user to after login
		});
	}

	// Do not allow user to visit login page or register page if they are logged in
	if (loggedIn && onlyWhenLoggedOut) {
		return next('/')
	}

	next();
});

window.router = router;

export default router;