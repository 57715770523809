<template>
	<v-container fluid fill-height>
		<v-layout align-center justify-center>
			<v-flex xs12 sm8 md6>
				<material-card color="info" :title="!register ? 'Вход' : 'Регистрация'">
					<template v-slot:headerRight>
						<v-btn outlined @click="register = !register">{{ !register ? 'зарегистрироваться' : 'войти' }}</v-btn>
					</template>
					<v-card>
						<v-alert :value="error ? true : false" type="error">{{ error }}</v-alert>
						<v-container>
							<v-layout align-center justify-space-between wrap mx-5>
								<login-social />
							</v-layout>
						</v-container>
						<hr />

						<v-form ref="form" v-model="valid" loading="loading" @submit="validate">
							<v-card-text>
								<v-text-field
									prepend-icon="icon-mail"
									v-model="email"
									:rules="emailRules"
									label="Email"
									name="email"
									:error-messages="mess.email"
									validate-on-blur
									required
								></v-text-field>

								<v-text-field
									:append-icon="show_password ? 'icon-eye-on' : 'icon-eye-off'"
									prepend-icon="icon-lock"
									:type="show_password ? 'text' : 'password'"
									v-model="password"
									:rules="[rule_password, rule_length_max(20), rule_required]"
									label="Пароль"
									validate-on-blur
									@click:append="show_password = !show_password"
									required
								></v-text-field>

								<v-expand-transition>
									<div v-if="register">
										<v-text-field
											prepend-icon="icon-attention-circled"
											@change="promoCheck"
											:error-messages="mess.promo"
											v-model="promo"
											label="Промокод"
											hint="Не обязателен для заполнения"
											:success="promoSuccess"
											:loading="promoLoading"
											append-icon="icon-chevron_right"
											validate-on-blur
											@click:append="promoCheck"
										></v-text-field>
									</div>
								</v-expand-transition>
							</v-card-text>
							<v-card-actions>
								<v-btn v-show="!register" color="error" :to="{ name: 'resetPassword'}" v-if="$vuetify.breakpoint.width > 340">Восстановить пароль</v-btn>
								<v-spacer></v-spacer>
								<div v-if="!loading">
									<v-btn
										color="success"
										type="submit"
										block
										validate-on-blur
									>{{ !register ? 'Войти' : 'Принять' }}</v-btn>
									<v-btn v-show="!register" text color="error" :to="{ name: 'resetPassword'}" v-if="$vuetify.breakpoint.width <= 340">Восстановить пароль</v-btn>
								</div>
								<div v-else>
									<v-progress-linear :indeterminate="true"></v-progress-linear>
								</div>
							</v-card-actions>
						</v-form>
					</v-card>
				</material-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { Validator } from "../../mixins/Validator";
import LoginSocial from "../../components/login-social.vue";

export default {
	mixins: [Validator],
	components: { LoginSocial },
	data: () => ({
		error: null,
		loading: false,
		promoLoading: false,
		promoSuccess: false,
		valid: false,
		email: null,
		show_password: false,
		register: false,
		promo: null,
		login: null,
		password: "",
		mess: {
			login: [],
			email: [],
			promo: ""
		}
	}),
	created() {
		document.title = "Вход";
		if (this.$route.params.promo && this.$route.params.promo.search(/^[-A-z\d.]+$/i) != -1) {
			this.promo = this.$route.params.promo;
			this.register = true;
			this.promoCheck();
		}
	},
	watch: {
		login: function() {
			this.mess.login = [];
			this.error = null;
		},
		email: function() {
			this.mess.email = [];
			this.error = null;
		}
	},
	methods: {
		_login() {
			let t = this,
				body = { email: t.email, password: t.password };

			window.ajax("/api/login/", { body: body }, function(data) {
				t.loading = false;
				if (data.errors) {
					let errors = data.errors;
					for (let field in errors) {
						t.mess[field] = errors[field];
					}
				}
				if (!data.error) {
					t.$store.commit("user/authSuccess", {
						token: data.token,
						user: data.user
					});

					t.$router.push('/');
					/*t.$router.push(
						t.$router.history.current.query.redirect || "/"
					);*/
				} else {
					t.error = data.error;
				}
			});
		},
		_register() {
			let t = this,
				body = {
					email: t.email,
					password: t.password,
					login: t.login,
					promo: t.promo
				};

			window.ajax("/api/register/", { body: body }, function(
				data,
				error
			) {
				if (data.errors) {
					let errors = data.errors;
					for (let field in errors) {
						t.mess[field] = errors[field];
					}
				}
				t.loading = false;
				if (error && !data.error) data.error = error;
				if (!data.error) {
					t.$store.commit("user/authSuccess", {
						token: data.token,
						user: data.user
					});

					t.$router.push("/");
				} else {
					t.error = data.error;
				}
			});
		},
		validate(e) {
			e.preventDefault();
			var t = this;
			t.error = null;
			if (!t.$refs.form.validate()) return;
			t.loading = true;

			if (t.register) t._register();
			else t._login();
		},
		promoCheck() {
			let t = this;
			t.promoLoading = true;

			window.ajax("/api/promo/check-reg/" + t.promo, {}, function(
				data,
				error
			) {
				t.promoLoading = false;
				if (error && !data.error) data.error = error;
				if (!data.error) {
					t.mess.promo = "";
					t.promoSuccess = true;
				} else {
					if (data.error == "not found")
						t.mess.promo = "Промокод не найден";
					else t.mess.promo = data.error;
				}
			});
		}
	}
};
</script>
